<template>
  <div>
    <b-table-simple
      v-if="getNotServices.length > 0"
      hover
      caption-top
      responsive
      class="rounded-bottom mb-0"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Müşteri Adı</b-th>
          <b-th>Durum</b-th>
          <b-th>Tarih / Saat</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="services in getNotServices"
          :key="services.id"
        >
          <b-td>
            <div>{{ services.customer }}</div>
            <small class="text-warning font-small-1">{{ services.company }}</small>
          </b-td>
          <b-td><span class="badge badge-danger">{{ services.status }}</span></b-td>
          <b-td>{{ services.appointment_date ? moment(services.appointment_date + ' ' + services.appointment_time).format('DD.MM.YYYY HH:mm') : '-' }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <p
      v-if="getNotServices.length > 0"
      class="text-muted mt-1 mb-0 font-italic text-right"
    >
      Son 10 kayıt gösterilmektedir.
    </p>
    <b-alert
      v-else
      show
      variant="warning"
      class="mb-0 text-center"
    >
      <div class="alert-body">
        <div>
          <feather-icon
            icon="InfoIcon"
            class="mr-50 mb-1 mt-1"
            size="36"
          />
        </div>
        <div>
          Henüz servise gelmeyen randevu bulunmamaktadır.
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BThead,
  BTbody,
  BAlert,
} from 'bootstrap-vue'

export default {
  name: 'NotComingCustomers',
  components: {
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BThead,
    BTbody,
    BAlert,
  },
  computed: {
    getNotServices() {
      return this.$store.getters['crmDashboard/getNotServices']
    },
  },
}
</script>

<style scoped>

</style>
