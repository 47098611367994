<template>
  <div>
    <b-row>
      <b-col md="3">
        <sales />
      </b-col>
      <b-col md="3">
        <g2-sales />
      </b-col>
      <b-col md="3">
        <alerts />
      </b-col>
      <b-col md="3">
        <requests />
      </b-col>
      <b-col md="12">
        <alert-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import g2Sales from '@/views/Dashboard/Common/Counters/g2Sales.vue'
import sales from '@/views/Dashboard/Common/Counters/Sales.vue'
import alerts from '@/views/Dashboard/Common/Counters/Alerts.vue'
import requests from '@/views/Dashboard/Common/Counters/Requests.vue'
import AlertList from '@/views/Dashboard/Common/Tables/AlertList.vue'

export default {
  name: 'Manager',
  components: {
    BRow,
    BCol,
    g2Sales,
    alerts,
    requests,
    AlertList,
    sales,
  },
}
</script>
