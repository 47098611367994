<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    :variant="variant"
    :opacity="opacity"
    :blur="blur"
  >
    <div
      class="cursor-pointer"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>Görüşmeler</b-card-title>
        </b-card-header>
        <b-table
          striped
          hover
          responsive="sm"
          small
          :fields="fields"
          :items="dataList"
        >
          <template #cell(customer)="data">
            <div class="d-flex align-content-around">
              <div class="mr-1">
                <b-button
                  variant="flat-success"
                  class="btn-icon"
                  pill
                  :to="'/interviews/view/' + data.item.interview_number"
                >
                  <FeatherIcon icon="EyeIcon" />
                </b-button>
              </div>
              <div>
                <div class="font-small-2">
                  {{ data.item.customer }}
                </div>
                <div
                  v-if="data.item.company_name"
                  class="font-small-1 text-primary"
                >
                  {{ data.item.company_name }}
                </div>
                <div
                  v-if="data.item.brand"
                  class="text-warning font-small-1"
                >
                  {{ data.item.brand }}
                </div>
                <div
                  v-if="data.item.interview_status"
                  class="text-muted font-small-1"
                >
                  {{ data.item.interview_status }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(username)="data">
            <div class="font-small-2 text-nowrap">
              {{ data.item.username }}
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <div class="text-muted d-block d-flex justify-content-between align-items-center">
            <div class="font-small-2">
              Son 10 adet görüşme listelenmektedir.
            </div>
            <b-button
              variant="flat-primary"
              size="sm"
              to="/interviews"
            >
              Tümünü Görüntüle
            </b-button>
          </div>
        </b-card-footer>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BOverlay,
  BTable,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Alerts',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BOverlay,
    BTable,
    BButton,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
          tdClass: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Danışman',
          tdClass: 'text-nowrap width-100',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['interviewListDashboard/dashboard']
    },
    loading() {
      return this.$store.getters['interviewListDashboard/loading']
    },
    getParams() {
      return this.$store.getters['dashboardFilter/getParams']
    },
  },
  watch: {
    getParams: {
      deep: true,
      handler() {
        this.getData()
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('interviewListDashboard/getData', {
        id_com_user: this.getParams.id_com_user,
        id_com_user_manager: this.getParams.id_com_user_manager,
        id_com_interview_type: this.getParams.id_com_interview_type,
        sdate: this.getParams.sdate,
        edate: this.getParams.edate,
      })
    },
  },
}
</script>
