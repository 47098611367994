<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Başlangıç Tarihi"
        for="sdate"
      >
        <b-form-datepicker
          id="sdate"
          v-model="getParams.sdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Bitiş Tarihi"
        for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="getParams.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'DatePicker',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    getParams() {
      return this.$store.getters['crmDashboard/getParams']
    },
  },
}
</script>

<style scoped>

</style>
