<template>
  <div>
    <b-table-simple
      v-if="getAlerts.length > 0"
      hover
      caption-top
      responsive
      class="rounded-bottom mb-0"
    >
      <b-thead
        head-variant="light"
        class="w-100"
      >
        <b-tr>
          <b-th>Müşteri Adı</b-th>
          <b-th>Tarih / Saat</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="alert in getAlerts"
          :key="alert.id"
        >
          <b-td>
            <div>{{ alert.customer }}</div>
            <div v-if="alert.company">
              <small class="text-warning">{{ alert.company }}</small>
            </div>
          </b-td>
          <b-td>
            <div>{{ alert.alertdate ? moment(alert.alertdate).format('DD.MM.YYYY') : '-' }}</div>
            <small class="text-danger font-italic">{{ alert.username }}</small>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <p
      v-if="getAlerts.length > 0"
      class="text-muted mt-1 mb-0 font-italic text-right"
    >
      Son 10 kayıt gösterilmektedir.
    </p>
    <b-alert
      v-else
      show
      variant="warning"
      class="mb-0 text-center"
    >
      <div class="alert-body">
        <div>
          <feather-icon
            icon="InfoIcon"
            class="mr-50 mb-1 mt-1"
            size="36"
          />
        </div>
        <div>
          Henüz hatırlatma bulunamadı.
        </div>
      </div>
    </b-alert>
    <b-button
      variant="flat-primary"
      size="sm"
      to="/alerts"
    >
      Tümünü Görüntüle
    </b-button>
  </div>
</template>

<script>
import {
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BTbody,
  BThead,
  BAlert,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Alerts',
  components: {
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BThead,
    BTbody,
    BAlert,
    BButton,
  },
  computed: {
    getAlerts() {
      return this.$store.getters['crmDashboard/getAlerts']
    },
  },
}
</script>

<style scoped>

</style>
