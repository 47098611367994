<template>
  <div>
    <b-row>
      <b-col md="4">
        <customers />
      </b-col>
      <b-col md="4">
        <interviews />
      </b-col>
      <b-col md="4">
        <sales />
      </b-col>
      <b-col md="6">
        <alerts />
      </b-col>
      <b-col md="6">
        <requests />
      </b-col>
      <b-col md="6">
        <interviews-list />
      </b-col>
      <b-col md="6">
        <alert-list />
      </b-col>
      <b-col md="12">
        <sales-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import customers from '@/views/Dashboard/Common/Counters/Customers.vue'
import interviews from '@/views/Dashboard/Common/Counters/Interviews.vue'
import sales from '@/views/Dashboard/Common/Counters/Sales.vue'
import alerts from '@/views/Dashboard/Common/Counters/Alerts.vue'
import requests from '@/views/Dashboard/Common/Counters/Requests.vue'
import interviewsList from '@/views/Dashboard/Common/Tables/InterviewsList.vue'
import AlertList from '@/views/Dashboard/Common/Tables/AlertList.vue'
import SalesList from '@/views/Dashboard/Common/Tables/SalesList.vue'

export default {
  name: 'Manager',
  components: {
    BRow,
    BCol,
    customers,
    interviews,
    alerts,
    requests,
    AlertList,
    sales,
    interviewsList,
    SalesList,
  },
}
</script>
