<template>
  <div>
    <date-picker v-if="dashboardComponent !== 'CrmManager' && dashboardComponent !== 'CrmUser'" />
    <component :is="dashboardComponent" />
  </div>
</template>

<script>
import moment from 'moment/moment'
import datePicker from '@/views/Dashboard/Common/datePicker.vue'
/* CRM */
import CrmManager from '@/views/Dashboard/Crm/Manager.vue'
import CrmUser from '@/views/Dashboard/Crm/User.vue'
/* Accessory */
import AccessoriesManager from '@/views/Dashboard/Accessories/Manager.vue'
import AccessoriesUser from '@/views/Dashboard/Accessories/User.vue'
import DamageUser from '@/views/Dashboard/Damage/User.vue'
import UsedCarsUser from '@/views/Dashboard/Used_cars/User.vue'
import Corporate from '@/views/Dashboard/Corporate/User.vue'
import AccountingManager from '@/views/Dashboard/Accounting/Manager.vue'
import AccountingUser from '@/views/Dashboard/Accounting/User.vue'
import MarketingManager from '@/views/Dashboard/Marketing/Manager.vue'
import MarketingUser from '@/views/Dashboard/Marketing/User.vue'
import PurchasingManager from '@/views/Dashboard/Purchasing/Manager.vue'
import PurchasingUser from '@/views/Dashboard/Purchasing/User.vue'
import SalesManager from '@/views/Dashboard/Sales/Manager.vue'
import SalesChief from '@/views/Dashboard/Sales/Chief.vue'
import SalesExecutive from '@/views/Dashboard/Sales/Executive.vue'
import SalesUser from '@/views/Dashboard/Sales/User.vue'
import SalesSupportManager from '@/views/Dashboard/Sales_support/Manager.vue'
import SalesSupportUser from '@/views/Dashboard/Sales_support/User.vue'
import ServiceManager from '@/views/Dashboard/Service/Manager.vue'
import ServiceUser from '@/views/Dashboard/Service/User.vue'
import SparePartsManager from '@/views/Dashboard/Spare_parts/Manager.vue'
import SparePartsUser from '@/views/Dashboard/Spare_parts/User.vue'
import InsuranceManager from '@/views/Dashboard/Insurance/Manager.vue'
import InsuranceUser from '@/views/Dashboard/Insurance/User.vue'
import RentalsManager from '@/views/Dashboard/Rentals/Manager.vue'
import RentalsUser from '@/views/Dashboard/Rentals/User.vue'
import RealtyManager from '@/views/Dashboard/Realty/Manager.vue'
import RealtyUser from '@/views/Dashboard/Realty/User.vue'
import SystemManager from '@/views/Dashboard/System_manager/User.vue'

export default {
  name: 'DashboardIndex',
  components: {
    datePicker,
    AccessoriesManager,
    AccessoriesUser,
    AccountingManager,
    AccountingUser,
    Corporate,
    CrmManager,
    CrmUser,
    DamageUser,
    InsuranceManager,
    InsuranceUser,
    MarketingManager,
    MarketingUser,
    PurchasingManager,
    PurchasingUser,
    RealtyManager,
    RealtyUser,
    RentalsManager,
    RentalsUser,
    SalesManager,
    SalesChief,
    SalesExecutive,
    SalesUser,
    SalesSupportManager,
    SalesSupportUser,
    ServiceManager,
    ServiceUser,
    SparePartsManager,
    SparePartsUser,
    SystemManager,
    UsedCarsUser,
  },
  data() {
    return {
      dashboardComponent: null,
    }
  },
  created() {
    this.setParams()
  },
  methods: {
    setParams() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      // Aksesuar
      if (userData.id_com_department === '26') {
        this.setAccessory(userData)
      }
      // Hasar
      if (userData.id_com_department === '9') {
        this.setDamage(userData)
      }
      // Ekspertiz
      if (userData.id_com_department === '4') {
        this.setExpertise(userData)
      }
      // Kurumsal
      if (userData.id_com_department === '24') {
        this.setCorporate(userData)
      }
      // Muhasebe
      if (userData.id_com_department === '8') {
        this.setAccounting(userData)
      }
      // Pazarlama
      if (userData.id_com_department === '16') {
        this.setMarketing(userData)
      }
      // Satın Alma
      if (userData.id_com_department === '17') {
        this.setPurchasing(userData)
      }
      // Sıfır Satış
      if (userData.id_com_department === '2') {
        this.setSales(userData)
      }
      // Satış Destek
      if (userData.id_com_department === '12') {
        this.setSalesSupport(userData)
      }
      // Servis
      if (userData.id_com_department === '3') {
        this.setService(userData)
      }
      // Yedek Parça
      if (userData.id_com_department === '6') {
        this.setSpareParts(userData)
      }
      // Sigorta
      if (userData.id_com_department === '7') {
        this.setInsurance(userData)
      }
      // Filo Kiralama
      if (userData.id_com_department === '5') {
        this.setRentals(userData)
      }
      // Gayrimenkul
      if (userData.id_com_department === '27') {
        this.setRealty(userData)
      }
      // CRM
      if (userData.id_com_department === '1') {
        this.setCrm(userData)
      }
      // Sistem Yöneticisi
      if (userData.id_com_user_type === '1') {
        this.setSystemManager()
      }
    },
    setAccessory(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'AccessoriesManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 11,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'AccessoriesUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 11,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setDamage(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'DamageUser'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 7,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'DamageUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 7,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setExpertise(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'UsedCars'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 3,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'UsedCars'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 3,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setCorporate(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'Corporate'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'Corporate'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setAccounting(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'AccountingManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'AccountingUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setMarketing(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'MarketingManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'MarketingUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setPurchasing(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'PurchasingManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'PurchasingUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setSales(userData) {
      if (userData.id_com_user_type === '2') {
        this.dashboardComponent = 'SalesManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 1,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else if (userData.id_com_user_type === '3') {
        this.dashboardComponent = 'SalesExecutive'
        // Executive
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 1,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else if (userData.id_com_user_type === '4') {
        this.dashboardComponent = 'SalesChief'
        // Executive
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 1,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'SalesUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 1,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setSalesSupport(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'SalesSupportManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'SalesSupportUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: null,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setService(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'ServiceManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'ServiceUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setSpareParts(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'SparePartsManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'SparePartsUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setInsurance(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'InsuranceManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'InsuranceUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setRentals(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'RentalsManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'RentalsUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setRealty(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'RealtyManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'RealtyUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 2,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setCrm(userData) {
      if (userData.id_com_user_type <= '4') {
        this.dashboardComponent = 'CrmManager'
        // Manager
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: null,
          id_com_brand: null,
          id_com_interview_type: 8,
          id_com_user_manager: userData.id,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      } else {
        this.dashboardComponent = 'CrmUser'
        // Danışman
        this.$store.commit('dashboardFilter/SET_PARAMS', {
          sdate: moment().startOf('month').format('YYYY-MM-DD'),
          edate: moment().endOf('month').format('YYYY-MM-DD'),
          id_com_user: userData.id,
          id_com_brand: null,
          id_com_interview_type: 8,
          id_com_user_manager: null,
          id_com_location: null,
          id_com_department: userData.id_com_department,
        })
      }
    },
    setSystemManager() {
      this.$store.commit('dashboardFilter/SET_PARAMS', {
        sdate: moment().startOf('month').format('YYYY-MM-DD'),
        edate: moment().endOf('month').format('YYYY-MM-DD'),
        id_com_user: null,
        id_com_brand: null,
        id_com_interview_type: null,
        id_com_user_manager: null,
        id_com_location: null,
        id_com_department: null,
      })
      this.dashboardComponent = 'SystemManager'
    },
  },
}
</script>
