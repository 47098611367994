<template>
  <div>
    <b-table-simple
      v-if="getPendingRequests.length > 0"
      hover
      caption-top
      responsive
      class="rounded-bottom mb-0"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Müşteri Adı</b-th>
          <b-th class="text-right">
            Başvuru No
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="request in getPendingRequests"
          :key="request.id"
          @click="$router.push(`/crm/view/` + request.id)"
        >
          <b-td>
            <div>{{ request.customer }}</div>
          </b-td>
          <b-td>
            <div class="text-right text-primary font-weight-bolder">
              #{{ request.id }}
              <p class="text-danger mb-0 font-italic font-small-2">
                {{ request.confirmation_user ? request.confirmation_user : '-' }}
              </p>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
      <p
        v-if="getPendingRequests.length > 0"
        class="text-muted mt-1 mb-0 font-italic text-right"
      >
        Son 10 kayıt gösterilmektedir.
      </p>
    </b-table-simple>
    <b-alert
      v-else
      show
      variant="warning"
      class="mb-0 text-center"
    >
      <div class="alert-body">
        <div>
          <feather-icon
            icon="InfoIcon"
            class="mr-50 mb-1 mt-1"
            size="36"
          />
        </div>
        <div>
          Henüz onay bekleyen başvuru bulunmamaktadır.
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BThead,
  BTbody, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'ApprovalWaitingCustomers',
  components: {
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BThead,
    BTbody,
    BAlert,
  },
  computed: {
    getPendingRequests() {
      return this.$store.getters['crmDashboard/getPendingRequests']
    },
  },
  methods: {
    toPage(id) {
      this.$router.push(`/crm/view/${id}`)
    },
  },
}
</script>

<style scoped>

</style>
