<template>
  <b-row>
    <b-col
      class="text-center"
      lg="3"
      md="6"
      sm="12"
    >
      <b-avatar
        class="mb-1"
        variant="light-success"
        size="45"
      >
        <feather-icon
          size="21"
          icon="TrendingUpIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ getinallar2Surveys.sales | toNumber }}
        </h2>
        <span>Satış</span>
      </div>
    </b-col>
    <b-col
      lg="3"
      md="6"
      sm="12"
      class="text-center"
    >
      <b-avatar
        class="mb-1"
        variant="light-info"
        size="45"
      >
        <feather-icon
          size="21"
          icon="PhoneOutgoingIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ getinallar2Surveys.surveys | toNumber }}
        </h2>
        <span>Anket</span>
      </div>
    </b-col>
    <b-col
      lg="3"
      md="6"
      sm="12"
      class="text-center"
    >
      <b-avatar
        class="mb-1"
        variant="light-danger"
        size="45"
      >
        <feather-icon
          size="21"
          icon="PhoneOffIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ getinallar2Surveys.others | toNumber }}
        </h2>
        <span>Galeri - Diğer</span>
      </div>
    </b-col>
    <b-col
      lg="3"
      md="6"
      sm="12"
      class="text-center"
    >
      <b-avatar
        class="mb-1"
        variant="light-warning"
        size="45"
      >
        <feather-icon
          size="21"
          icon="PieChartIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ getinallar2Surveys.score }}
        </h2>
        <span>Memnuniyet Oranı</span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'Surveys',
  components: {
    BRow,
    BCol,
    BAvatar,
  },
  computed: {
    getinallar2Surveys() {
      return this.$store.getters['crmDashboard/getinallar2Surveys']
    },
  },
}
</script>

<style scoped>

</style>
