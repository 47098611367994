<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    :variant="variant"
    :opacity="opacity"
    :blur="blur"
  >
    <div
      class="cursor-pointer"
      @click="$router.push('/g2sales')"
    >
      <statistic-card
        :statistic="g2sales.users"
        :totals="g2sales.totals"
        :icon="g2sales.icon"
        :statistic-title="g2sales.label"
        :color="g2sales.variant"
      />
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import statisticCard from '@/views/Dashboard/components/StatisticCard.vue'

export default {
  name: 'Sales',
  components: {
    BOverlay,
    statisticCard,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
    }
  },
  computed: {
    g2sales() {
      return this.$store.getters['g2SalesDashboard/dashboard']
    },
    loading() {
      return this.$store.getters['g2SalesDashboard/loading']
    },
    getParams() {
      return this.$store.getters['dashboardFilter/getParams']
    },
  },
  watch: {
    getParams: {
      deep: true,
      handler() {
        this.getData()
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('g2SalesDashboard/getData', {
        id_com_user: this.getParams.id_com_user,
        id_com_user_manager: this.getParams.id_com_user_manager,
        sdate: this.getParams.sdate,
        edate: this.getParams.edate,
      })
    },
  },
}
</script>
