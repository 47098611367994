<template>
  <div>
    <b-card>
      <datePicker />
    </b-card>
    <b-row class="match-height">
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Anketler">
          <surveys />
        </b-card>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Başvurular">
          <requests />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Onay Bekleyen Servis Randevuları">
          <pendingServices />
        </b-card>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Teslim Edilen Araç Adedi">
          <deliveredCars />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Onay Bekleyen Başvurular">
          <pendingRequests />
        </b-card>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Servise Gelmeyen Randevular">
          <notServices />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Açık Hatırlatmalar">
          <alerts />
        </b-card>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-card title="Açık Görüşme Notları">
          <interviews />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import datePicker from '@/views/Dashboard/Crm/components/datePicker'
// eslint-disable-next-line import/extensions
import requests from '@/views/Dashboard/Crm/components/requests'
// eslint-disable-next-line import/extensions
import surveys from '@/views/Dashboard/Crm/components/surveys'
// eslint-disable-next-line import/extensions
import pendingServices from '@/views/Dashboard/Crm/components/pendingServices'
// eslint-disable-next-line import/extensions
import deliveredCars from '@/views/Dashboard/Crm/components/deliveredCars'
// eslint-disable-next-line import/extensions
import pendingRequests from '@/views/Dashboard/Crm/components/pendingRequests'
// eslint-disable-next-line import/extensions
import notServices from '@/views/Dashboard/Crm/components/notServices'
// eslint-disable-next-line import/extensions
import alerts from '@/views/Dashboard/Crm/components/alerts'
// eslint-disable-next-line import/extensions
import interviews from '@/views/Dashboard/Crm/components/interviews'

export default {
  name: 'Manager',
  components: {
    BCard,
    BRow,
    BCol,
    datePicker,
    requests,
    surveys,
    pendingServices,
    deliveredCars,
    pendingRequests,
    notServices,
    alerts,
    interviews,
  },
  computed: {
    getParams() {
      return this.$store.getters['crmDashboard/getParams']
    },
  },
  watch: {
    getParams: {
      deep: true,
      handler() {
        this.getinallar2Surveys()
        this.getRequestMethods()
        this.getPendingServices()
        this.getDeliveredCarsCounts()
        this.getPendingRequests()
        this.getNotServies()
        this.getAlerts()
        this.getInterviews()
      },
    },
  },
  created() {
    this.getinallar2Surveys()
    this.getRequestMethods()
    this.getPendingServices()
    this.getDeliveredCarsCounts()
    this.getPendingRequests()
    this.getNotServies()
    this.getAlerts()
    this.getInterviews()
  },
  methods: {
    getinallar2Surveys() {
      this.$store.dispatch('crmDashboard/inallar2Surveys', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getRequestMethods() {
      this.$store.dispatch('crmDashboard/requestCounts', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getPendingServices() {
      this.$store.dispatch('crmDashboard/pendingServices', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getDeliveredCarsCounts() {
      this.$store.dispatch('crmDashboard/deliveredCarsCounts', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getPendingRequests() {
      this.$store.dispatch('crmDashboard/pendingRequests', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getNotServies() {
      this.$store.dispatch('crmDashboard/notServices', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getAlerts() {
      this.$store.dispatch('crmDashboard/alerts', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
    getInterviews() {
      this.$store.dispatch('crmDashboard/interviews', { sdate: this.getParams.sdate, edate: this.getParams.edate })
    },
  },
}
</script>

<style scoped></style>
