<template>
  <div>
    <b-row>
      <b-col
        class="text-center"
        lg="3"
        md="6"
        sm="12"
      >
        <b-avatar
          class="mb-1"
          variant="light-warning"
          size="45"
        >
          <feather-icon
            size="21"
            icon="ClockIcon"
          />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ getRequestCounts[1] | toNumber }}
          </h2>
          <span>Beklemede</span>
        </div>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
        class="text-center"
      >
        <b-avatar
          class="mb-1"
          variant="light-info"
          size="45"
        >
          <feather-icon
            size="21"
            icon="EyeIcon"
          />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ getRequestCounts[2] | toNumber }}
          </h2>
          <span>Takip Ediliyor</span>
        </div>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
        class="text-center"
      >
        <b-avatar
          class="mb-1"
          variant="light-success"
          size="45"
        >
          <feather-icon
            size="21"
            icon="CheckIcon"
          />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ getRequestCounts[3] | toNumber }}
          </h2>
          <span>Çözümlendi</span>
        </div>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
        class="text-center"
      >
        <b-avatar
          class="mb-1"
          variant="light-danger"
          size="45"
        >
          <feather-icon
            size="21"
            icon="FlagIcon"
          />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ getRequestCounts[4] | toNumber }}
          </h2>
          <span>Onay Bekliyor</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'Statictics',
  components: {
    BRow,
    BCol,
    BAvatar,
  },
  computed: {
    getRequestCounts() {
      return this.$store.getters['crmDashboard/getRequestCounts']
    },
  },
}
</script>

<style scoped>

</style>
