<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    :variant="variant"
    :opacity="opacity"
    :blur="blur"
  >
    <div
      class="cursor-pointer"
      @click="$router.push('/customers')"
    >
      <statistic-card
        :statistic="customers.users"
        :totals="customers.totals"
        :icon="customers.icon"
        :statistic-title="customers.label"
        :color="customers.variant"
      />
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import statisticCard from '@/views/Dashboard/components/StatisticCard.vue'

export default {
  name: 'Customers',
  components: {
    BOverlay,
    statisticCard,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customersDashboard/dashboard']
    },
    loading() {
      return this.$store.getters['customersDashboard/loading']
    },
    getParams() {
      return this.$store.getters['dashboardFilter/getParams']
    },
  },
  watch: {
    getParams: {
      deep: true,
      handler() {
        this.getData()
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('customersDashboard/getData', {
        id_com_user: this.getParams.id_com_user,
        id_com_user_manager: this.getParams.id_com_user_manager,
        sdate: this.getParams.sdate,
        edate: this.getParams.edate,
      })
    },
  },
}
</script>
