<template>
  <div>
    <b-row>
      <b-col md="4">
        <expertise-requests />
      </b-col>
      <b-col md="4">
        <interviews />
      </b-col>
      <b-col md="4">
        <alerts />
      </b-col>
      <b-col md="6">
        <interviews-list />
      </b-col>
      <b-col md="6">
        <alert-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import interviews from '@/views/Dashboard/Common/Counters/Interviews.vue'
import alerts from '@/views/Dashboard/Common/Counters/Alerts.vue'
import expertiseRequests from '@/views/Dashboard/Common/Counters/Expertise.vue'
import interviewsList from '@/views/Dashboard/Common/Tables/InterviewsList.vue'
import AlertList from '@/views/Dashboard/Common/Tables/AlertList.vue'

export default {
  name: 'User',
  components: {
    BRow,
    BCol,
    interviews,
    alerts,
    interviewsList,
    AlertList,
    expertiseRequests,
  },
}
</script>
