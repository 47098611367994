<template>
  <b-row class="mb-2">
    <b-col>
      <b-form-datepicker
        id="sdate"
        v-model="getParams.sdate"
        v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
    </b-col>
    <b-col>
      <b-form-datepicker
        id="edate"
        v-model="getParams.edate"
        v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'DatePicker',
  components: {
    BRow,
    BCol,
    BFormDatepicker,
  },
  computed: {
    getParams() {
      return this.$store.getters['dashboardFilter/getParams']
    },
  },
}
</script>
